<template>
    <painel titulo="Usuário" icone="pi pi-users" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-3">
                <label>Tipo</label>
                <Dropdown v-model="tipoUsuario" :options="tipos" optionLabel="descricao" optionValue="id" />
            </div>
            <div class="field col-12 md:col-3">
                <label>Login</label>
                <div class="p-inputgroup">
                    <InputText type="text" v-model="login" :disabled="desabilitaLogin" @input="v$.login.$touch()" />
                    <Button
                        icon="pi pi-search"
                        class="p-button-warning"
                        @click="$emit('pesquisarLogin', login)"
                        v-if="this.acao === 'INSERIR' && !desabilitaPesquisarLogin"
                        title="Pesquisar usuário"
                    />
                    <Button
                        icon="pi pi-refresh"
                        class="p-button-warning"
                        @click="limparUsuario()"
                        v-if="this.acao === 'INSERIR' && desabilitaRefresh"
                        title="Limpar pesquisa usuário"
                    />
                </div>
                <small class="p-error" v-if="loginMax">O login deve ter no máximo 20 caracteres</small>
                <small class="p-error" v-if="v$.login.$error">O login é obrigatório</small>
            </div>
            <div class="field col-12 md:col-6">
                <label>Nome</label>
                <InputText type="text" v-model="nome" :disabled="desabilitaForm" @input="v$.nome.$touch()" />
                <small class="p-error" v-if="v$.nome.$error">O nome é obrigatório</small>
            </div>
            <div class="field col-12 md:col-4">
                <label>Email</label>
                <InputText type="text" v-model="email" :disabled="desabilitaForm" @input="v$.email.$touch()" />
                <small class="p-error" v-if="v$.email.$error">O email é obrigatório</small>
            </div>
        </div>
        <slot name="botoes"></slot>
        <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" />
        <Button label="Salvar" icon="pi pi-check-circle" class="ml-2 p-button-primary" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        acao: {
            type: String,
        },

        usuario: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },
    },

    emits: ['salvar', 'atualizar', 'pesquisarCpf'],

    data() {
        return {
            loginMax: null,
            nome: null,
            login: null,
            email: null,
            tipo: null,
            desabilitaPesquisarLogin: false,
            desabilitaRefresh: false,
            desabilitaLogin: false,
            desabilitaForm: true,
            tipoUsuario: 0,
            tipos: [
                { descricao: 'Interno', id: 0 },
                { descricao: 'Externo', id: 1 },
            ],
        };
    },

    validations() {
        return {
            nome: { required },
            login: {
                required
            },
            email: { required, email },
        };
    },

    created() {
        if (this.acao == 'INSERIR') {
            this.desabilitaForm = true;
        }
    },

    methods: {
        preencher() {
            this.nome = this.usuario?.nome.toUpperCase();
            this.email = this.usuario?.email.toLowerCase();
            this.v$.$touch();
            this.desabilitaPesquisarLogin = true;
            this.desabilitaRefresh = true;
            this.desabilitaLogin = true;
        },

        limpar() {
            this.nome = '';
            this.login = '';
            this.email = '';
            this.v$.$reset();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o usuário?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            if(!this.loginMax && !this.v$.login.$error && !this.v$.nome.$error && !this.v$.email.$error) {
                let usuarioDto = {
                nome: this.nome,
                login: this.login,
                email: this.email,
                tipo: this.tipoUsuario,
            };
            this.$emit('salvar', usuarioDto);
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Usuario',
                    detail: 'O login não pode ter mais de 20 caracteres',
                    life: 3000,
                });
            }
           
        },

        atualizar() {
            this.$emit('atualizar');
        },

        limparUsuario() {
            this.desabilitaPesquisarLogin = false;
            this.desabilitaRefresh = false;
            this.nome = '';
            this.login = '';
            this.email = '';
            this.v$.$reset();
            this.desabilitaLogin = false;
        },
    },

    watch: {
        usuario() {
            this.preencher();
        },

        login() {
            if(this.login.length > 20) {
                this.loginMax = true
            } else {
                this.loginMax = false
            }
               
        },

        tipoUsuario() {
            this.limpar();
            if (this.tipoUsuario == 0) {
                this.desabilitaPesquisarLogin = false;
                this.desabilitaLogin = false;
                this.desabilitaForm = true;
                this.tipoUsuario = 0;
            }
            if (this.tipoUsuario == 1) {
                this.tipoUsuario = 1;
                this.desabilitaPesquisarLogin = true;
                this.desabilitaLogin = false;
                this.desabilitaForm = false;
                this.desabilitaPesquisarLogin = true;
                this.desabilitaRefresh = false;
            }
        },
    },
};
</script>
